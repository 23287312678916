// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 1001;
  width: 100%;
  background-color: #086e38;
}
.footer-wrapper .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 16px;
}
@media (max-width: 767px) {
  .footer-wrapper .footer-content {
    gap: 10px;
  }
}
.footer-wrapper .footer-logo {
  max-width: 60px;
}
.footer-wrapper .footer-text {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}
@media (max-width: 767px) and (min-width: 359px) {
  .footer-wrapper .footer-text {
    font-size: 12px;
  }
  .footer-wrapper .footer-text.text-hotline-service {
    flex: 0 0 125px;
  }
  .footer-wrapper .footer-text.text-copywrite {
    flex: 0 0 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,yBAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;EACA,aAAA;AACR;AAAQ;EAPJ;IAQQ,SAAA;EAGV;AACF;AAAI;EACI,eAAA;AAER;AACI;EACI,eAAA;EACA,WAAA;EACA,gBAAA;AACR;AACQ;EALJ;IAMQ,eAAA;EAEV;EAAU;IACI,eAAA;EAEd;EACU;IACI,eAAA;EACd;AACF","sourcesContent":[".footer-wrapper {\n    position: absolute;\n    bottom: 0;\n    z-index: 1001;\n    width: 100%;\n    background-color: #086e38;\n\n    .footer-content {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        flex-wrap: wrap;\n        gap: 20px;\n        padding: 16px;\n        @media (max-width:767px) {\n            gap: 10px;\n        }\n    }\n\n    .footer-logo {\n        max-width: 60px;\n    }\n\n    .footer-text {\n        font-size: 14px;\n        color: #fff;\n        font-weight: 700;\n\n        @media (max-width:767px) and (min-width: 359px) {\n            font-size: 12px;\n\n            &.text-hotline-service {\n                flex: 0 0 125px;\n            }\n\n            &.text-copywrite {\n                flex: 0 0 100px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
