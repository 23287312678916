// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  position: relative;
}

.map-section {
  height: calc(100vh - 80px);
  position: relative;
}
@media (max-width: 991.9px) {
  .map-section {
    height: calc(100vh - 63px);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.leaflet-marker-draggable {
  z-index: 1500 !important;
}

.leaflet-routing-remove-waypoint:after {
  right: -152px !important;
  top: -35.5px !important;
  height: 6px !important;
  margin-top: 0 !important;
  padding-right: 4px !important;
  background: #fff !important;
}

.leaflet-routing-geocoder {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AACA;EACE,0BAAA;EACA,kBAAA;AAEF;AADE;EAHF;IAIQ,0BAAA;EAIN;AACF;;AADA;EACE,cAAA;EACA,oBAAA;AAIF;;AADA;EACE,wBAAA;AAIF;;AADA;EACE,wBAAA;EACA,uBAAA;EACA,sBAAA;EACA,wBAAA;EACA,6BAAA;EACA,2BAAA;AAIF;;AADA;EACE,kBAAA;AAIF;;AADA;EACE;IACE,4CAAA;EAIF;AACF;AADA;EACE,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,YAAA;AAGF;;AAAA;EACE,cAAA;AAGF;;AAIA;EACE;IACE,uBAAA;EADF;EAIA;IACE,yBAAA;EAFF;AACF","sourcesContent":[".App {\n  position: relative;\n}\n.map-section {\n  height: calc(100vh - 80px);\n  position: relative;\n  @media (max-width: 991.9px) {\n        height: calc(100vh - 63px);\n  }\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.leaflet-marker-draggable {\n  z-index: 1500 !important;\n}\n\n.leaflet-routing-remove-waypoint:after {\n  right: -152px !important;\n  top: -35.5px !important;\n  height: 6px !important;\n  margin-top: 0 !important;\n  padding-right: 4px !important;\n  background: #fff !important;\n}\n\n.leaflet-routing-geocoder {\n  text-align: center;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n// .leaflet-control-attribution {\n//   display: none !important;\n// }\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
