import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tooltip,
  useMediaQuery,
  Drawer,
  List,
} from "@mui/material";
import {
  Search,
  Room,
  Map,
  Menu,
  Traffic,
  Language,
  Streetview,
  TrafficTwoTone,
  NearMe,
  MapTwoTone,
  FormatListBulleted,
  ArrowForward,
} from "@mui/icons-material";
import "./header.scss";
import Logo from "../../assets/img/CE_mobile_icon.png";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { MAP_VIEWS } from "../../store/constants/mapViewConstants";
import { useTheme } from "@mui/styles";

const Header = ({
  onLocationSelect,
  handleShowLocation,
  setSourceCity,
  setDestinationCity,
  setRouteDetails,
  handleTripPlanner,
  setMapView,
}) => {
  const [selectedPlace, setSelectedPlace] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [currentView, setCurrentView] = useState("normal");
  const [trafficView, setTrafficView] = useState("normal");
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:1140px)");
  const theme = useTheme();
  // const [tripPlannerOpen, setTripPlannerOpen] = useState(false);

  const handlePlaceSelect = async (place) => {
    try {
      resetState();
      setRouteDetails(null);
      setSelectedPlace(place.label);
      setInputValue(place.label);
      const results = await geocodeByAddress(place.label);
      const latLng = await getLatLng(results[0]);
      onLocationSelect(latLng);
    } catch (error) {
      console.error("Error geocoding address:", error);
    }
  };

  const handleSearchClick = async () => {
    if (inputValue) {
      try {
        resetState();
        const results = await geocodeByAddress(inputValue);
        const latLng = await getLatLng(results[0]);
        onLocationSelect(latLng);
        setInputValue("");
      } catch (error) {
        console.error("Error geocoding address:", error);
      }
    } else {
      console.error("No location entered");
    }
  };

  const resetState = () => {
    setRouteDetails(null);
    setSourceCity(null);
    setDestinationCity(null);
    onLocationSelect(null);
  };

  const handleTripPlannerRest = async () => {
    try {
      resetState();
      handleTripPlanner();
    } catch (err) {
      console.error("handleTripPlanner ~ err:", err);
    }
  };

  const handleChangeMapView = (viewType) => {
    try {
      setCurrentView(viewType);
      setMapView(MAP_VIEWS[viewType]);
      if (viewType === "normal") {
        setTrafficView("normal");
      }
    } catch (err) {
      console.error("handleChangeMapView ~ err:", err);
    }
  };
  const handleTrafficView = (viewType) => {
    try {
      setTrafficView(viewType);
      setMapView(MAP_VIEWS[viewType]);
      if (viewType === "traffic") {
        setCurrentView("normal");
      }
    } catch (err) {
      console.error("handleTrafficView ~ err:", err);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle drawer toggle for mobile
  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <AppBar
      position="static"
      className="header"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {isMobile ? (
        <>
          <Box className="mobileHeader">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box className="mobileHeaderLogo">
                <img src={Logo} alt="Logo" className="logo" />
              </Box>
              <Typography variant="h6" className="mobileRenewableText">
                <span>Renewable</span> Natural Gas
              </Typography>
              <div className="grow" />
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => toggleDrawer(true)}
              sx={{ ml: "auto" }}
            >
              <Menu sx={{ paddingRight: "10px", fontSize: "30px" }} />
            </IconButton>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={() => toggleDrawer(false)}
            >
              <Box
                className="drawerContainer"
                role="presentation"
                onClick={() => toggleDrawer(false)}
              >
                <Box className="drawerContent">
                  <IconButton
                    color="inherit"
                    className="findNearStation iconButton"
                    onClick={handleShowLocation}
                  >
                    <Room />
                    <Typography variant="body1" className="iconText">
                      Find a Nearby Station
                    </Typography>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    className="iconButton"
                    onClick={handleTripPlannerRest}
                  >
                    {/* <Map /> */}
                    <Box className="tripPlannerIconWrap">
                      <NearMe />
                    </Box>
                    <Typography variant="body1" className="iconText">
                      Trip Planner
                    </Typography>
                  </IconButton>

                  <div className="search">
                    <IconButton className="searchBarIcon" color="inherit" onClick={handleSearchClick}>
                      <Room />
                    </IconButton>
                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E" // Replace with your Google Places API Key
                      selectProps={{
                        value: selectedPlace,
                        onChange: handlePlaceSelect, // Custom handler for selected place
                        placeholder: "",
                        className: "headerLocationSearch",
                      }}
                    />
                    <IconButton className="searchBarIcon" color="inherit" onClick={handleSearchClick}>
                      <ArrowForward sx={{ color: "#fff" }} />
                    </IconButton>
                  </div>
                  {/* <Tooltip
                    title={
                      trafficView === "traffic"
                        ? "Switch to Traffic View"
                        : "Switch to Map View"
                    }
                    arrow
                  >
                    <IconButton
                      color="inherit"
                      onClick={() =>
                        handleTrafficView(
                          trafficView === "traffic" ? "normal" : "traffic"
                        )
                      }
                    >
                      {trafficView === "traffic" ? (
                        <Traffic sx={{ color: "#fff" }} />
                      ) : (
                        <TrafficTwoTone
                          sx={{ color: "#fff" }}
                          className="menu-icon"
                        />
                      )}
                    </IconButton>
                  </Tooltip> */}
                  <IconButton
                    color="inherit"
                    className="iconButton"
                    onClick={() =>
                      handleTrafficView(
                        trafficView === "traffic" ? "normal" : "traffic"
                      )
                    }
                  >
                    {trafficView === "traffic" ? (
                      <MapTwoTone />
                    ) : (
                      <Map className="menu-icon" />
                    )}
                    <Typography variant="body1" className="iconText">
                      {trafficView === "traffic"
                        ? "Switch to Traffic View"
                        : "Switch to Map View"}
                    </Typography>
                  </IconButton>
                  <IconButton
                    color="inherit"
                    className="iconButton"
                    onClick={() =>
                      handleChangeMapView(
                        currentView === "normal" ? "arial" : "normal"
                      )
                    }
                  >
                    {currentView === "normal" ? (
                      <FormatListBulleted className="menu-icon" />
                    ) : (
                      <Streetview className="menu-icon" />
                    )}
                    <Typography variant="body1" className="iconText">
                      {currentView === "normal"
                        ? "Switch to Arial View"
                        : "Switch to Map View"}
                    </Typography>
                  </IconButton>
                  {/* <Tooltip
                    title={
                      currentView === "normal"
                        ? "Switch to Arial View"
                        : "Switch to Map View"
                    }
                  >
                    <IconButton
                      color="inherit"
                      className="mapViewIcon iconButton"
                      onClick={() =>
                        handleChangeMapView(
                          currentView === "normal" ? "arial" : "normal"
                        )
                      }
                    >
                      {currentView === "normal" ? (
                        <Language className="menu-icon" />
                      ) : (
                        <Streetview className="menu-icon" />
                      )}
                    </IconButton>
                  </Tooltip> */}
                </Box>
              </Box>
            </Drawer>
          </Box>
        </>
      ) : (
        <Toolbar className="toolbar">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box className="header-logo">
              <img src={Logo} alt="Logo" className="logo" />
            </Box>
            <Typography variant="h6" className="renewable">
              <span>Renewable</span> Natural Gas
            </Typography>
            <div className="grow" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              // gap: {
              //   xs: "10px",
              //   xl: "20px",
              // },
            }}
          >
            <IconButton
              color="inherit"
              className="findNearStation iconButton"
              onClick={handleShowLocation}
            >
              <Room />
              <Typography variant="body1" className="iconText">
                Find a Nearby Station
              </Typography>
            </IconButton>
            <IconButton
              color="inherit"
              className="iconButton"
              onClick={handleTripPlannerRest}
            >
              {/* <Map /> */}
              <Box className="tripPlannerIconWrap">
                <NearMe />
              </Box>
              <Typography variant="body1" className="iconText">
                Trip Planner
              </Typography>
            </IconButton>

            <div className="search">
              <IconButton className="searchBarIcon" color="inherit" onClick={handleSearchClick}>
                <Room />
              </IconButton>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyDWSTyX3tb1R56qY1wYEkQrhUgyKmNjS8E" // Replace with your Google Places API Key
                selectProps={{
                  value: selectedPlace,
                  onChange: handlePlaceSelect, // Custom handler for selected place
                  placeholder: "",
                  className: "headerLocationSearch",
                }}
              />
              <IconButton className="searchBarIcon" color="inherit" onClick={handleSearchClick}>
                <ArrowForward />
              </IconButton>
            </div>
            <Tooltip
              title={
                trafficView === "traffic"
                  ? "Switch to Traffic View"
                  : "Switch to Map View"
              }
              arrow
            >
              <IconButton
                color="inherit"
                className="mapIconBtn"
                onClick={() =>
                  handleTrafficView(
                    trafficView === "traffic" ? "normal" : "traffic"
                  )
                }
              >
                {trafficView === "traffic" ? (
                  <MapTwoTone />
                ) : (
                  <Map className="menu-icon" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                currentView === "normal"
                  ? "Switch to Arial View"
                  : "Switch to Map View"
              }
            >
              <IconButton
                color="inherit"
                className="mapViewIcon iconButton"
                disableRipple={true}
                disableFocusRipple={true}
                onClick={() =>
                  handleChangeMapView(
                    currentView === "normal" ? "arial" : "normal"
                  )
                }
              >
                {currentView === "normal" ? (
                  <FormatListBulleted className="menu-icon" />
                ) : (
                  <Streetview className="menu-icon" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      )}
      {/* <TripPlanner
        isOpen={tripPlannerOpen}
        setDestinationCity={setDestinationCity}
        setSourceCity={setSourceCity}
        onClose={() => {
          setTripPlannerOpen(false);
        }}
      /> */}
    </AppBar>
  );
};

export default Header;
