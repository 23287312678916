// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.station-detail-selected {
  width: 150px;
}
.station-detail-selected h6 {
  font-size: 14px;
  margin: 0px 0px 10px 0px;
}
.station-detail-selected button {
  background: #eea729;
  width: 100%;
  border: none;
  color: #fff;
  border-radius: 40px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  height: 32px;
}
.station-detail-selected button:hover {
  background: #f49e0a;
}

.zoomBtnWrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.zoomBtnWrapper .zoomInBtn {
  border-bottom: 2px solid rgb(230, 230, 230);
  border-radius: 0;
}
.zoomBtnWrapper .zoomInBtn svg {
  font-size: 14px;
}
.zoomBtnWrapper .zoomOutBtn {
  border-radius: 0;
}
.zoomBtnWrapper .zoomOutBtn svg {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/MapContainer/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,eAAA;EACA,wBAAA;AACJ;AAEE;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,YAAA;AAAJ;AAEI;EACE,mBAAA;AAAN;;AAKA;EACE,sBAAA;EACA,aAAA;EACA,sBAAA;AAFF;AAIE;EACE,2CAAA;EACA,gBAAA;AAFJ;AAGI;EACE,eAAA;AADN;AAKE;EACE,gBAAA;AAHJ;AAIQ;EACI,eAAA;AAFZ","sourcesContent":[".station-detail-selected {\n  width: 150px;\n\n  h6 {\n    font-size: 14px;\n    margin: 0px 0px 10px 0px;\n  }\n\n  button {\n    background: #eea729;\n    width: 100%;\n    border: none;\n    color: #fff;\n    border-radius: 40px;\n    box-shadow: none;\n    font-size: 14px;\n    font-weight: 700;\n    text-transform: capitalize;\n    height: 32px;\n\n    &:hover {\n      background: #f49e0a;\n    }\n  }\n}\n\n.zoomBtnWrapper {\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n\n  .zoomInBtn {\n    border-bottom: 2px solid rgb(230, 230, 230);\n    border-radius: 0;\n    svg {\n      font-size: 14px;\n    }\n  }\n\n  .zoomOutBtn {\n    border-radius: 0;\n        svg {\n            font-size: 14px;\n          }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
