// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookie-disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #333;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-disclaimer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.cookie-disclaimer p {
  margin: 0;
  padding-right: 10px;
}

.cookie-disclaimer button {
  background: #f1c40f;
  border: none;
  padding: 10px 20px;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/CookieDisclaimer/CookieDisclaimer.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;AACJ;;AAEE;EACE,SAAA;EACA,mBAAA;AACJ;;AAEE;EACE,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".cookie-disclaimer {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background: #333;\n    color: white;\n    padding: 15px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n  }\n  \n  .cookie-disclaimer-content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    max-width: 1200px;\n    width: 100%;\n  }\n  \n  .cookie-disclaimer p {\n    margin: 0;\n    padding-right: 10px;\n  }\n  \n  .cookie-disclaimer button {\n    background: #f1c40f;\n    border: none;\n    padding: 10px 20px;\n    color: #333;\n    cursor: pointer;\n    border-radius: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
