import { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import { useMap } from 'react-leaflet';
import { MARKERS } from '../../assets/markers/markers';

// L.Marker.prototype.options.icon = L.icon({
//   iconUrl: MARKERS[0],
//   iconSize: [60, 60],
//   iconAnchor: [30, 60],
// });

const RoutingOnly = ({
  sourceCity,
  destinationCity,
  destinations,
  zoomed = true,
}) => {
  const map = useMap();

  const getIcon = (index) => {
    const customIconStart = L.icon({
      iconUrl: MARKERS[index],
      iconSize: [60, 60],
      iconAnchor: [30, 60],
      //   popupAnchor: [0, -30],
    });

    return customIconStart;
  };

  useEffect(() => {
    if (!map) return;

    if (sourceCity?.lat !== undefined && destinationCity?.lat !== undefined) {
      if (map.locate) {
        map.stopLocate(); // Stop locating if it's causing issues
      }
      const routingControl = L.Routing.control({
        waypoints: [
          L.latLng(parseFloat(sourceCity.lat), parseFloat(sourceCity.lng)),
          ...(destinations?.map((destination) =>
            L.latLng(parseFloat(destination.lat), parseFloat(destination.lng))
          ) || []),
          L.latLng(
            parseFloat(destinationCity.lat),
            parseFloat(destinationCity.lng)
          ),
        ],
        createMarker: function (i, waypoint, n) {
          return L.marker(waypoint.latLng, {
            icon: getIcon(i),
          });
        },
        routeWhileDragging: false,
        lineOptions: {
          styles: [{ color: '#6FA1EC', weight: 4 }],
        },
        show: false,
        showAlternatives: false,
        draggableWaypoints: false,
        addWaypoints: false,
        fitSelectedRoutes: zoomed,
      }).addTo(map);

      return () => {
        if (map && routingControl) {
          map.removeControl(routingControl);
        }
      };
    }
  }, [map, sourceCity, destinationCity]);

  return null;
};

export default RoutingOnly;
