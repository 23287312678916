import React, { useEffect, useState } from "react";
import "./PromotionPopup.scss";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const PromotionPopup = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const promotionData = useSelector((state) => state?.station?.promotionData);
  const isSingleOrMultiple = Array.isArray(promotionData);

  const settings = {
    dots: false,
    infinite: promotionData?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  useEffect(() => {
    if (promotionData) {
      setIsLoading(false);
    }
  }, [promotionData]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "500px" } }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DialogTitle id="alert-dialog-title">Special Promotion!</DialogTitle>
        <Box sx={{ marginRight: 1 }}>
          <IconButton color="inherit" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Slider className="promotionSlider" {...settings}>
                {isSingleOrMultiple ? (
                  promotionData.map((promotion, index) => (
                    <Box key={index} sx={{ maxWidth: "560px", width: "100%" }}>
                      <Link to={promotion?.Link || "#"}>
                        <img
                          src={
                            promotion?.Image ||
                            "https://cnglngstations.com/MobileApp/Mobile-Banner.jpg"
                          }
                          alt={`Promotion ${index + 1}`}
                          className="promotion-img"
                        />
                      </Link>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ maxWidth: "560px", width: "100%" }}>
                    <Link to={promotionData?.Link || "#"}>
                      <img
                        src={
                          promotionData?.Image ||
                          "https://cnglngstations.com/MobileApp/Mobile-Banner.jpg"
                        }
                        alt="Promotion"
                        className="promotion-img"
                      />
                    </Link>
                  </Box>
                )}
              </Slider>
            </Box>
          </DialogContentText>
        </DialogContent>
      )}

      {!isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
            gap: "10px",
          }}
        >
          <Link
            to={
              isSingleOrMultiple
                ? promotionData[0]?.Link
                : promotionData?.Link || "#"
            }
            className="modalLink"
          >
            Learn More
          </Link>
          <Button variant="text" onClick={onClose}>
            No, Thanks
          </Button>
        </Box>
      )}
    </Dialog>
  );
};

export default PromotionPopup;
