import React, { useState, useEffect } from "react";
import "./App.scss";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import Header from "./components/Header";
import MapComponent from "./components/MapContainer";
import SearchOptions from "./components/SearchOption";
import StationDetails from "./components/StationDetails";
import {
  getPromotionLinks,
  getStationsImagesData,
} from "./store/actions/stationActions";
import { useDispatch } from "react-redux";
import PromotionPopup from "./components/PromotionPopup/index";
import CookieDisclaimer from "./components/CookieDisclaimer";
// import TripPlanner from "./components/TripPlanner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrintableRoutes from "./components/PrintableRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TripPlanner from "./components/TripPlanner";
import { MAP_VIEWS } from "./store/constants/mapViewConstants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/Footer";
import ThemeRegistry from "./components/ThemeRegistry";
function App() {
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState({ lat: 38.8951, lng: -77.0364 });
  const [selectedStation, setSelectedStation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  console.log("App ~ filterValues:", filterValues);
  const [position, setPosition] = useState(null);
  const [showPromotion, setShowPromotion] = useState(true);
  const [sourceCity, setSourceCity] = useState(null);
  const [destinationCity, setDestinationCity] = useState(null);
  const [routeDetails, setRouteDetails] = useState();
  const [destinations, setDestinations] = useState([]);
  const [mapView, setMapView] = useState(MAP_VIEWS.normal);

  console.log("App ~ sourceCity destinationCity:", destinationCity);
  const [tripPlannerOpen, setTripPlannerOpen] = useState(false);

  const handleGetDirectionsClick = (station) => {
    console.log("handleGetDirectionsClick ~ station:", station);
    setDestinationCity({
      lat: station?.Latitude,
      lng: station?.Longitude,
      name: station?.StationName,
    });
    setTripPlannerOpen(true);
    handleShowLocation();
  };

  const handleLocationSelect = (location) => {
    setMapCenter(location);
    setPosition(location);
  };

  const handleStationSelect = (station) => {
    setPosition(null);
    setSelectedStation(station);
  };

  const handleOpenModal = (station) => {
    setOpenModal(true);
  };

  const handleClearTrip = () => {
    if (window?.localStorage) {
      window?.localStorage?.removeItem("SOURCE_CITY");
      window?.localStorage?.removeItem("DESTINATION_CITY");
      window?.localStorage?.removeItem("DESTINATIONS_METADATA");
    }
    setDestinationCity(null);
    setSourceCity(null);
    setRouteDetails(null);
    setDestinations([]);
  };

  const handleShowLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        // setTripPlannerOpen(false);

        if (destinationCity && sourceCity) {
          handleClearTrip();
        }
        if (result.state === "denied") {
          toast.error("Please enable location services to use this feature.");
        } else {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const { latitude, longitude } = pos.coords;
              const userLocation = { lat: latitude, lng: longitude };
              setSourceCity(userLocation);
              setMapCenter(userLocation);
              setPosition(userLocation);
              toast.success("Location retrieved successfully!");
              console.log("Current Locaton sourceCity ", userLocation);
            },
            () => {
              toast.error("Unable to retrieve location.");
            }
          );
        }
      });
    } else {
      toast.error("Geolocation is not supported by your browser.");
    }
  };

  const handleTripPlanner = async () => {
    try {
      setTripPlannerOpen(!tripPlannerOpen);
    } catch (err) {
      console.error("handleTripPlanner ~ err:", err);
    }
  };

  useEffect(() => {
    dispatch(getPromotionLinks());
  }, [dispatch]);

  return (
    <ThemeProvider theme={ThemeRegistry}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Box className="App">
                <ToastContainer />
                <Header
                  onLocationSelect={handleLocationSelect}
                  handleShowLocation={handleShowLocation}
                  setSourceCity={setSourceCity}
                  setDestinationCity={setDestinationCity}
                  setRouteDetails={setRouteDetails}
                  handleTripPlanner={handleTripPlanner}
                  setMapView={setMapView}
                />
                <Box className="map-section">
                  <MapComponent
                    filterValues={filterValues}
                    center={mapCenter}
                    onStationSelect={handleStationSelect}
                    onOpenModal={handleOpenModal}
                    position={position}
                    handleShowLocation={handleShowLocation}
                    sourceCity={sourceCity}
                    destinationCity={destinationCity}
                    setRouteDetails={setRouteDetails}
                    routeDetails={routeDetails}
                    setSourceCity={setSourceCity}
                    destinations={destinations}
                    mapView={mapView}
                  />
                  <SearchOptions
                    setFilterValues={setFilterValues}
                    routeDetails={routeDetails}
                  />

                  {tripPlannerOpen && (
                    <TripPlanner
                      // isOpen={tripPlannerOpen}
                      sourceCity={sourceCity}
                      destinationCity={destinationCity}
                      setDestinationCity={setDestinationCity}
                      setSourceCity={setSourceCity}
                      onClose={() => {
                        setTripPlannerOpen(false);
                      }}
                      destinations={destinations}
                      setRouteDetails={setRouteDetails}
                      routeDetails={routeDetails}
                      setDestinations={setDestinations}
                    />
                  )}
                  <StationDetails
                    station={selectedStation}
                    isOpenModal={openModal}
                    setOpenModal={setOpenModal}
                    onGetDirectionsClick={handleGetDirectionsClick}
                  />
                </Box>
                <Footer />
                {/* <PromotionPopup
                isOpen={showPromotion}
                onClose={() => setShowPromotion(false)}
              /> */}
                <CookieDisclaimer />
              </Box>
            }
          />
          <Route
            path="/printable-routes"
            element={
              <PrintableRoutes
                destinations={destinations}
                routeDetails={routeDetails}
                sourceName={sourceCity?.name}
                destinatioinName={destinationCity?.name}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
