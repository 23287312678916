function extractValues(filters) {
  return Object.values(filters).flat();
}

const KEYS_MAP = {
  CNG: 'StationFuelTypeCNG',
  LNG: 'StationFuelTypeLNG',
  'Vans and box trucks': 'VehicleTypesBoxTrucks',
  'Refuse and buses': 'VehicleTypesCarsAndVans',
  'Tractor-trailers': 'VehicleTypesSemiTrucks',
  Operational: 'StationStatus',
  'Under Maintenance': 'StationStatus',
  'Coming Soon': 'StationStatus',
  '24/7': 'HoursOpen',
  'Open Now': 'HoursOpenIs24H',
  Comdata: 'PaymentTypesAcceptedComData',
  'Fuelman Fleetwide': 'PaymentTypesAcceptedFuelmanFleetwide',
  'Mastercard Fleet': 'PaymentTypesAcceptedMasterCardFleet',
  TCH: 'PaymentTypesAcceptedTCH',
  'Visa Fleet': 'PaymentTypesAcceptedVisaFleet',
  Amex: 'PaymentTypesAcceptedAmex',
  Discover: 'PaymentTypesAcceptedDiscover',
  Tcheck: 'PaymentTypesAcceptedTcheck',
  'Clean Energy Fuel Card': 'PaymentTypesAcceptedCleanEnergyFuelCard',
  EFS: 'PaymentTypesAcceptedEFS',
  'Legacy EFS': 'PaymentTypesAcceptedLegacyEFS',
  'PFGift Card': 'PaymentTypesAcceptedPFGiftCard',
  Voyager: 'PaymentTypesAcceptedVoyager',
  Fleetone: 'PaymentTypesAcceptedWEXFleetone',
  'PFGIFT Card': 'PaymentTypesAcceptedPFGiftCard',
  Mastercard: 'PaymentTypesAcceptedMasterCard',
  Speedway: 'PaymentTypesAcceptedSpeedway',
  Visa: 'PaymentTypesAcceptedVisa',
};

const positiveValuesRef = {
  StationStatus: ['Active', 'Under Maintenance', 'Coming Soon'],
  Operational: 'Active',
  'Under Maintenance': 'Under Maintenance',
  'Coming Soon': 'Coming Soon',
  StationFuelTypeCNG: 'Yes',
  StationFuelTypeLNG: 'Yes',
  HoursOpen: 'Open 24/7;',
  HoursOpenIs24H: 'Yes',
  VehicleTypesCarsAndVans: 'Yes',
  VehicleTypesBoxTrucks: 'Yes',
  VehicleTypesSemiTrucks: 'Yes',
  PaymentTypesAcceptedCleanEnergyFuelCard: 'Yes',
  PaymentTypesAcceptedMasterCard: 'Yes',
  PaymentTypesAcceptedVisa: 'Yes',
  PaymentTypesAcceptedDiscover: 'Yes',
  PaymentTypesAcceptedVoyager: 'Yes',
  PaymentTypesAcceptedTranStar: 'Yes',
  PaymentTypesAcceptedNaturalFuels: 'Yes',
  PaymentTypesAcceptedAmex: 'Yes',
  PaymentTypesAcceptedComData: 'Yes',
  PaymentTypesAcceptedEFS: 'Yes',
  PaymentTypesAcceptedFuelmanFleetwide: 'Yes',
  PaymentTypesAcceptedLegacyEFS: 'Yes',
  PaymentTypesAcceptedMasterCardFleet: 'Yes',
  PaymentTypesAcceptedPFGiftCard: 'Yes',
  PaymentTypesAcceptedSpeedway: 'Yes',
  PaymentTypesAcceptedTCH: 'Yes',
  PaymentTypesAcceptedTcheck: 'Yes',
  PaymentTypesAcceptedVisaFleet: 'Yes',
  PaymentTypesAcceptedWEXFleetone: 'Yes',
};

export const filterServerData = (filters, targetArray) => {
  const noSelectedFilters = Object.keys(filters).length === 0;
  if (noSelectedFilters) {
    return targetArray;
  }
  // step 1 - get keys to be filtered
  const filterKeys = extractValues(filters);
  const noFilterKeys = Object.keys(filterKeys).length === 0;
  if (noFilterKeys) {
    return targetArray;
  }
  // step 2 - create filterRef
  const filterRef = {};

  for (const filterKey of filterKeys) {
    const serverKey = KEYS_MAP[filterKey];
    if (Array.isArray(positiveValuesRef[serverKey])) {
      filterRef[serverKey] =
        filters[serverKey] ??
        filters[serverKey[0].toLowerCase() + serverKey.slice(1)].map(
          (key) => positiveValuesRef[key]
        );
      continue;
    } else {
      filterRef[serverKey] = positiveValuesRef[serverKey];
    }
  }

  // step 3 - create filterRef
  const filteredArray = targetArray.filter((obj) => {
    return Object.keys(filterRef).some((key) => {
      if (!filterRef[key]) {
        return false;
      }
      if (!obj[key]) {
        return false;
      }
      if (Array.isArray(filterRef[key])) {
        return filterRef[key].some(
          (val) => val.trim().toLowerCase() === obj[key].trim().toLowerCase()
        );
      }

      return filterRef[key].toLowerCase() === obj[key].toLowerCase();
    });
  });

  return filteredArray;
};

export const fetchLocationName = async (lat, lng) => {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=10&addressdetails=1`
    );
    const data = await response.json();
    if (data && data.address) {
      return data.display_name; // Get the readable location name
    }
    return `Un-Named Location with Lat: ${lat}, Lng: ${lng}`;
  } catch (error) {
    console.error('Error fetching location name:', error);
  }
};
